import { z } from 'zod';
import { SurveySchema } from "./survey.model";
import { UserSchema } from './user.model';
import { PreSurveySchema } from './presurvey.model';
import { SpeciesFeatureNoteSchema } from './speciesfeaturenote.model';


const PolygonSchema = z.object({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(z.array(z.number())))
});

const MultiPolygonSchema = z.object({
  type: z.literal('MultiPolygon'),
  coordinates: z.array(z.array(z.array(z.array(z.number()))))
});

const ProjectGeometrySchema = z.union([PolygonSchema, MultiPolygonSchema]);

export const ProjectSummarySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullable(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    geometry: ProjectGeometrySchema.nullable(),
    ukHabVersion: z.string(),
    organisationId: z.number()
  })
  .strict();

export type ProjectSummary = z.infer<typeof ProjectSummarySchema>;

export const ProjectSchema = ProjectSummarySchema.extend({
  geometry: z.any(),
  role: z.string(),
  surveys: SurveySchema.array(),
  presurveys: PreSurveySchema.array(),
  speciesFeatureNotes: SpeciesFeatureNoteSchema.array().optional(),
  users: UserSchema.array(),
  speciesAttachmentAttributeId: z.number().optional(),
  sampleSpeciesAttachmentAttributeId: z.number().optional(),
  sampleAttachmentAttributeId: z.number().optional(),
  surveyAttachmentAttributeId: z.number().optional(),
  presurveyL2AttributePath: z.string().optional(),
  surveyFormId: z.number(),
  preSurveyFormId: z.number(),
  shareableLink: z.string()
}).strict();

const ProjectMembershipSchema = z.object({
  role: z.string(),
  userId: z.number()
});

export type Project = z.infer<typeof ProjectSchema>;
export type ProjectGeometry = z.infer<typeof ProjectGeometrySchema>;

export type ProjectUpdate = Partial<Pick<Project, 'name' | 'description' | 'geometry'>>;

export type ProjectMembership = z.infer<typeof ProjectMembershipSchema>;
