import { NgModule, inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  RouterModule,
  Routes
} from '@angular/router';
import { MAPS_SERVICE_PERSIST, MapsService } from './core/services/maps.service';
import { UserService } from './core/services/user.service';
import { ProjectService } from './features/project/project.service';
import { projectResolver } from './project.resolver';
import { surveyResolver } from './survey.resolver';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);
  !userService.isAuthenticated() && router.navigate(['/login']);
  return userService.isAuthenticated();
};

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./features/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'projects/:id',
    resolve: {
      project: projectResolver
    },
    providers: [ProjectService],
    canActivate: [authGuard],
    children: [{
      path: '',
      loadComponent: () =>
        import('./features/project/project.component').then(
          (m) => m.ProjectComponent
        ),
      children: [
        {
          path: '',
          redirectTo: 'baseline',
          pathMatch: 'full'
        },
        {
          path: 'baseline',
          children: [{
            path: '',
            loadComponent: () => import('./features/project/baseline/project-baseline-table.component').then(m => m.ProjectBaselineTableComponent),
          }, {
            path: '',
            outlet: 'toolbar',
            loadComponent: () => import('./features/project/baseline/project-baseline-toolbar.component').then(m => m.ProjectBaselineToolbarComponent),
          }, {
            path: '',
              outlet: 'actions',
              loadComponent: () => import('./features/project/baseline/project-baseline-actions.component').then(m => m.ProjectBaselineActionsComponent),
            }, {
              path: '',
            outlet: 'sidebar',
            loadComponent: () => import('./features/project/project-sidebar.component').then(m => m.ProjectSidebarComponent),
            children: [
              {
                path: 'activity',
                loadComponent: () =>
                  import('./features/project/project-activity.component').then(
                    (m) => m.ProjectActivityComponent
                  )
              },
              {
                path: 'stats',
                loadComponent: () =>
                  import('./features/project/project-stats.component').then(
                    (m) => m.ProjectStatsComponent
                  )
              },
              {
                path: 'filter',
                loadComponent: () =>
                  import('./features/project/project-filter.component').then(
                    (m) => m.ProjectFilterComponent
                  )
              },
              {
                path: 'geometry',
                loadComponent: () =>
                  import('./features/project/project-geometry.component').then(
                    (m) => m.ProjectGeometryComponent
                  )
              },
            ]
          }]
        },
        {
          path: 'pre-survey',
          children: [{
            path: '',
            loadComponent: () => import('./features/project/pre-survey/project-pre-survey-table.component').then(m => m.ProjectPreSurveyTableComponent)
          }, {
            path: '',
            outlet: 'toolbar',
            loadComponent: () => import('./features/project/pre-survey/project-pre-survey-toolbar.component').then(m => m.ProjectPreSurveyToolbarComponent),
          }]
        },
        {
          path: 'species-feature-notes',
          children: [{
            path: '',
            loadComponent: () => import('./features/project/species-features-notes/project-species-feature-notes-table.component').then(m => m.ProjectSpeciesFeatureNotesTableComponent)
          }, {
            path: '',
            outlet: 'toolbar',
            loadComponent: () => import('./features/project/species-features-notes/project-species-feature-notes-toolbar.component').then(m => m.ProjectSpeciesFeatureNotesToolbarComponent),
          }]
        }

      ]
    }, {
        path: 'baseline/:surveyid',
        providers: [
          MapsService,
          {
            provide: MAPS_SERVICE_PERSIST,
            useValue: false
          }],
        loadComponent: () =>
          import('./features/survey/survey.component').then(
            (m) => m.SurveyComponent
          ),
        resolve: {
          survey: surveyResolver
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./features/survey/survey-results.component').then(
                (m) => m.SurveyResultsComponent
              )
          },
          {
            path: 'species',
            pathMatch: 'full',
            loadComponent: () =>
              import('./features/survey/survey-species.component').then(
                (m) => m.SurveySpeciesComponent
              )
          },
          {
            path: 'species/quadrat',
            pathMatch: 'full',

            loadComponent: () =>
              import('./features/survey/survey-species.component').then(
                (m) => m.SurveySpeciesComponent
              )
          },
          {
            path: 'species/linear',
            pathMatch: 'full',

            loadComponent: () =>
              import('./features/survey/survey-species.component').then(
                (m) => m.SurveySpeciesComponent
              )
          },
          {
            path: 'condition',
            loadComponent: () =>
              import('./features/survey/survey-condition-criteria.component').then(
                (m) => m.SurveyConditionCriteriaComponent
              )
          },
          {
            path: 'photos',
            loadComponent: () =>
              import('./features/survey/survey-photos.component').then(
                (m) => m.SurveyPhotosComponent
              )
          }
        ]
      }]
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./core/auth/login.component').then((m) => m.LoginComponent)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
