import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { JwtService } from "../services/jwt.service";


export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const jwtService = inject(JwtService);
  const token = jwtService.getToken();
  const isApi = req.url.startsWith(environment.coreoApiUrl) || req.url.startsWith(environment.ukhabApiUrl);
  const isGraphql = req.url.endsWith('graphql');

    const request = req.clone({
      setHeaders: {
        ...(token && isApi ? { Authorization: `JWT ${token}` } : {})
      },
      setParams: {
        ...(token && !isGraphql ? { token } : {})
      }
    });
  return next(request);
}
