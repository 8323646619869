import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { filter, map } from 'rxjs';
import { ProjectHeaderComponent } from 'src/app/features/project/project-header.component';
import { Organisation } from '../models/organisation.model';
import { OrganisationsService } from '../services/organisations.service';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    RouterLink,
    SvgIconComponent,
    ProjectHeaderComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
  button.org-disabled{
    border: none;
    box-shadow: none;
    pointer-events: none;
  }
  nav{
    grid-template-columns: 1fr auto 1fr;
  }
  `]
})
export class HeaderComponent {


  private userService = inject(UserService);
  private organisationsService = inject(OrganisationsService);
  private router = inject(Router);

  image = this.userService.userImage;
  loggedIn = this.userService.isAuthenticated;
  organisation = this.organisationsService.currentOrganisation;
  organisations = this.organisationsService.organisations;

  url$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((e: NavigationEnd) => e.urlAfterRedirects),
  );

  url = toSignal(this.url$, { initialValue: '' });

  canSwitchOrg = computed(() => {
    const url = this.url();
    const count = this.organisationsService.organisationsCount();
    return count > 0 && url === '/';
  })

  switchOrganisation(org: Organisation) {
    this.organisationsService.setOrganisation(org.id);
  }

  logout() {
    this.userService.logout();
  }

}
