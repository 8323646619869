import { Inject, Injectable, InjectionToken, Optional, computed, signal } from "@angular/core";
import { Feature, FeatureCollection, Geometry } from "geojson";
import * as mapboxgl from "mapbox-gl";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SpeciesFeatureNote } from "../models/speciesfeaturenote.model";
import { difference, polygon } from '@turf/turf';
import { feature as featureHelper, MultiPolygon, Polygon } from '@turf/helpers';

interface UKHabSybmologyStripes {
  type: 'stripes';
  backgroundColor?: string;
  color: string;
  lineWidth: number;
  angle: number;
  dash: boolean;
}

interface UKHabSybmologyDots {
  type: 'dots';
  backgroundColor?: string;
  color: string;
}

interface UKHabSybmologyRhombs {
  type: 'rhombs';
  backgroundColor?: string;
  color: string;
  border: boolean;
}

export interface UKHabDataLayer {
  id: string;
  label: string;
  color: string;
  active: boolean;
}

type UKHabSybmology = UKHabSybmologyStripes | UKHabSybmologyDots | UKHabSybmologyRhombs | string;

type UKHabL2 = 'c' | 'w' | 'f' | 'g' | 'u' | 't' | 's' | 'r' | 'h';
type COLOR = `#${string}`;

export const MAP_DEFAULT_BOUNDS: mapboxgl.LngLatBoundsLike = [
  2.0153808593734936, 56.6877748258257,
  -7.0153808593762506, 47.45206245445874
];

export const mapboxStreetsStyle = 'mapbox://styles/mapbox/streets-v11';
export const mapboxSatelliteStyle = 'mapbox://styles/mapbox/satellite-v9';
export const emptyStyle: mapboxgl.Style = {
  version: 8,
  name: 'empty',
  metadata: {
    'mapbox:autocomposite': true,
    'mapbox:type': 'template'
  },
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {},
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'rgba(255,255,255,0)'
      }
    }
  ]
};

const fillOpacity = (opacity: number): mapboxgl.Expression => {
  const factor = opacity / 100;
  return [
    'case',
    ['boolean', ['feature-state', 'editing'], false],
    0.0 * factor,
    ['boolean', ['feature-state', 'hover'], false],
    0.9 * factor,
    ['boolean', ['feature-state', 'selected'], false],
    1.0 * factor,
    0.7 * factor
  ]
}

const styles = {
  'streets': mapboxStreetsStyle,
  'satellite': mapboxSatelliteStyle,
  'bing': null,
  'empty': emptyStyle
} as const;

export type MapStyleId = keyof typeof styles;

const ukHabL2Colors: Record<UKHabL2, COLOR> = {
  c: '#FF7E00',
  w: '#339f2c',
  f: '#fd7bee',
  g: '#97ef5d',
  u: '#eb2244',
  t: '#1b53d6',
  s: '#a7a7a4',
  r: '#29ecf5',
  h: '#fafc81'
};

const ukHabSybmology: [string, UKHabSybmology][] = [
  ['c1f', {type: 'rhombs', color: '#FFFFFF', backgroundColor: ukHabL2Colors['c'], border: false}],
  ['c1e', {type: 'rhombs', color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', border: false}],
  ['c1d', {type: 'dots', color: '#FFFFFF', backgroundColor: ukHabL2Colors['c']}],
  ['c1c', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 0, dash: false}],
  ['c1b', {type: 'stripes', lineWidth: 1, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 90, dash: false}],
  ['c1a', {type: 'dots', color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF'}],
  ['c1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['c', ukHabL2Colors['c']],

  ['f2e', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['f'], backgroundColor: '#FAFC81', angle: 0, dash: false}],
  ['f2d', {type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['f'], angle: 90, dash: false}],
  ['f2a', {type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['f'], angle: 45, dash: false}],
  ['f2', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['f'], backgroundColor: '#FAFC81', angle: 135, dash: false}],
  ['f1b', {type: 'stripes', lineWidth: 1, color: ukHabL2Colors['f'], backgroundColor: '#FFFFFF', angle: 90, dash: false}],
  ['f1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['f'], angle: 45, dash: false}],
  ['f1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['f'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['f', ukHabL2Colors['f']],

  ['g4', '#CBFFB6'],
  ['g3c8', {type: 'dots', color: '#CBFFB6', backgroundColor: ukHabL2Colors['g']}],
  ['g3c7', {type: 'rhombs', color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', border: true}],
  ['g3c6', {type: 'rhombs', color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], border: false}],
  ['g3c5', {type: 'dots', color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6'}],
  ['g3c', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6', angle: 0, dash: false}],
  ['g3b', {type: 'stripes', lineWidth: 1, color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], angle: 90, dash: false}],
  ['g3a', {type: 'stripes', lineWidth: 4, color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false}],
  ['g3', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6', angle: 135, dash: false}],
  ['g2b', {type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], angle: 90, dash: false}],
  ['g2a', {type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false}],
  ['g2', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#FAFC81', angle: 135, dash: false}],
  ['g1c', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 0, dash: false}],
  ['g1b6', {type: 'rhombs', color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], border: false}],
  ['g1b5', {type: 'dots', color: ukHabL2Colors['g'], backgroundColor: '#FAFC81'}],
  ['g1b', {type: 'stripes', lineWidth: 1, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 90, dash: false}],
  ['g1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false}],
  ['g1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['g', ukHabL2Colors['g']],

  ['h3', {type: 'stripes', lineWidth: 4, color: '#EB2244', backgroundColor: '#8268D6', angle: 135, dash: false}],
  ['h2b', {type: 'stripes', lineWidth: 2, color: '#EB2244', backgroundColor: '#8268D6', angle: 0, dash: true}],
  ['h2a', {type: 'stripes', lineWidth: 2, color: '#000000', backgroundColor: '#8268D6', angle: 0, dash: true}],
  ['h2', {type: 'stripes', lineWidth: 2, color: '#FAFC81', backgroundColor: '#8268D6', angle: 0, dash: true}],
  ['h1b6', {type: 'rhombs', color: '#FFFFFF', backgroundColor: '#8268D6', border: false}],
  ['h1b5', {type: 'dots', color: '#8268D6', backgroundColor: '#FFFFFF'}],
  ['h1b', {type: 'stripes', lineWidth: 1, color: '#8268D6', backgroundColor: '#FFFFFF', angle: 90, dash: false}],
  ['h1a7', {type: 'rhombs', color: '#8268D6', backgroundColor: '#FFFFFF', border: false}],
  ['h1a5', {type: 'dots', color: '#FFFFFF', backgroundColor: '#8268D6'}],
  ['h1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: '#8268D6', angle: 45, dash: false}],
  ['h1', {type: 'stripes', lineWidth: 4, color: '#8268D6', backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['h', ukHabL2Colors['h']],

  ['r2', {type: 'dots', color: '#FAFC81', backgroundColor: ukHabL2Colors['r']}],
  ['r1e', {type: 'dots', color: ukHabL2Colors['r'], backgroundColor: '#FFFFFF'}],
  ['r1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['r'], angle: 45, dash: false}],
  ['r1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['r'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['r', ukHabL2Colors['r']],

  ['s3b', {type: 'stripes', lineWidth: 1, color: '#CFCFCA', backgroundColor: ukHabL2Colors['s'], angle: 90, dash: false}],
  ['s3a', {type: 'stripes', lineWidth: 4, color: '#CFCFCA', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false}],
  ['s3', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#CFCFCA', angle: 135, dash: false}],
  ['s2a', {type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false}],
  ['s2', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#FAFC81', angle: 135, dash: false}],
  ['s1d', {type: 'dots', color: ukHabL2Colors['s'], backgroundColor: '#FFFFFF'}],
  ['s1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false}],
  ['s1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['s', ukHabL2Colors['s']],

  ['t2d', {type: 'dots', color: ukHabL2Colors['t'], backgroundColor: '#FAFC81'}],
  ['t2a', {type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['t'], angle: 45, dash: false}],
  ['t2', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['t'], backgroundColor: '#FAFC81', angle: 135, dash: false}],
  ['t1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['t'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['t', ukHabL2Colors['t']],

  ['u1e', '#F36F85'],
  ['u1d', {type: 'rhombs', color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF', border: false}],
  ['u1c', {type: 'dots', color: '#FFFFFF', backgroundColor: ukHabL2Colors['u']}],
  ['u1b6', {type: 'rhombs', color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], border: false}],
  ['u1b5', {type: 'dots', color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF'}],
  ['u1b', {type: 'stripes', lineWidth: 1, color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], angle: 90, dash: false}],
  ['u1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], angle: 45, dash: false}],
  ['u1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['u', ukHabL2Colors['u']],

  ['w2c', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#FAFC81', angle: 0, dash: false}],
  ['w2b', {type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false}],
  ['w2a', {type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false}],
  ['w2', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['w'], backgroundColor: '#FAFC81', angle: 135, dash: false}],
  ['w1h', {type: 'dots', color: ukHabL2Colors['w'], backgroundColor: '#A94900'}],
  ['w1g6', {type: 'stripes', lineWidth: 2, color: '#000000', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: true}],
  ['w1g', {type: 'dots', color: '#A94900', backgroundColor: ukHabL2Colors['w']}],
  ['w1f', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#A94900', angle: 0, dash: false}],
  ['w1e', {type: 'stripes', lineWidth: 1, color: '#A94900', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false}],
  ['w1d', {type: 'stripes', lineWidth: 4, color: '#A94900', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false}],
  ['w1c', {type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#FFFFFF', angle: 0, dash: false}],
  ['w1b', {type: 'stripes', lineWidth: 1, color: '#FFFFFF', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false}],
  ['w1a', {type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false}],
  ['w1', {type: 'stripes', lineWidth: 4, color: ukHabL2Colors['w'], backgroundColor: '#FFFFFF', angle: 135, dash: false}],
  ['w', ukHabL2Colors['w']],
];

const symbologyColors: mapboxgl.Expression = [
  'match',
  ['get', 'l2'],
  'c',
  ukHabL2Colors['c'],
  'w',
  ukHabL2Colors['w'],
  'f',
  ukHabL2Colors['f'],
  'g',
  ukHabL2Colors['g'],
  'u',
  ukHabL2Colors['u'],
  't',
  ukHabL2Colors['t'],
  's',
  ukHabL2Colors['s'],
  'r',
  ukHabL2Colors['r'],
  'h',
  ukHabL2Colors['h'],
  '#000000'
];

const lineColorMap: [string, string, string][] = [
  ['f2d', '#fd7bee', '#f6f176'],

  ['g3c', '#97ef5d', '#cbfeb5'],
  ['g3c5', '#97ef5d', '#cbfeb5'],
  ['g3c6', '#97ef5d', '#cbfeb5'],
  ['g3c7', '#97ef5d', '#cbfeb5'],
  ['g3c8', '#97ef5d', '#cbfeb5'],

  ['h2', '#8167d6', '#f6f176'],

  ['h2a', '#8167d6', '#000000'],
  ['h2a5', '#8167d6', '#000000'],
  ['h2a6', '#8167d6', '#000000'],

  ['h2b', '#8167d6', '#ec2243'],

  ['r1e', '#29ecf5', '#f6f176'],
  ['u1e', '#ec2243', '#ec2243'],
  ['w1g6', '#339f2c', '#000000'],
];

const lineColors: mapboxgl.Expression = [
  'match',
  ['get', 'ukHabPCode'],
  ...lineColorMap.reduce<string[]>((acc, [code, color]) => [...acc, code, color], []),
  'c',
  ukHabL2Colors['c'],
  'w',
  ukHabL2Colors['w'],
  'f',
  ukHabL2Colors['f'],
  'g',
  ukHabL2Colors['g'],
  'u',
  ukHabL2Colors['u'],
  't',
  ukHabL2Colors['t'],
  's',
  ukHabL2Colors['s'],
  'r',
  ukHabL2Colors['r'],
  'h',
  ukHabL2Colors['h'],
  '#000000'
];

const lineDashColors: mapboxgl.Expression = [
  'match',
  ['get', 'ukHabPCode'],
  ...lineColorMap.reduce<string[]>((acc, [code, _, dash]) => [...acc, code, dash], []),
  '#000'
];

export const emptyFeatureCollection = <T = any>(): FeatureCollection<Geometry, T> => ({
  type: 'FeatureCollection',
  features: []
});

export const pointToBox = (point: mapboxgl.Point, boxSize: number): [mapboxgl.PointLike, mapboxgl.PointLike] => {
  return [
    [point.x - boxSize, point.y - boxSize],
    [point.x + boxSize, point.y + boxSize]
  ];
};

export const MAPS_SERVICE_PERSIST = new InjectionToken<boolean>('MAPS_SERVICE_PERSIST');

@Injectable()
export class MapsService {

  public readonly style = signal<MapStyleId>('streets');
  public readonly styleLoaded = signal<boolean>(false);
  public readonly featureOpacity = signal<number>(100);
  public readonly settingsOpen = signal<boolean>(false);

  public readonly DATA_SOURCE_NAME = 'data';
  public readonly REDLINE_SOURCE_NAME = 'redline';
  public readonly DATA_LAYERS_SOURCE = 'ukhab-portal';
  public readonly NOTES_DATA_SOURCE = 'notes';

  private map = signal<mapboxgl.Map | undefined>(undefined);

  public readonly mapReady = computed(() => {
    return typeof this.map() !== 'undefined' && this.styleLoaded();
  });

  private persist: boolean = true;


  dataLayers = signal<UKHabDataLayer[]>([{
    id: 'sssi-england',
    label: 'SSSI England',
    color: '#f00',
    active: false
  }, {
    id: 'sssi-scotland',
    label: 'SSSI Scotland',
    color: '#0f0',
    active: false
  }, {
    id: 'aonb-england',
    label: 'AONB England',
    color: '#00f',
    active: false
  }, {
    id: 'ramsar-england',
    label: 'Ramsar England',
    color: '#ff6600',
    active: false
  }, {
    id: 'nnr-england',
    label: 'National Nature Reserves England',
    color: '#f0f',
    active: false
  }, {
    id: 'lnr-england',
    label: 'Local Nature Reserves England',
    color: '#00fbf3',
    active: false
  }]);

  constructor(
    @Optional() @Inject(MAPS_SERVICE_PERSIST) persist: boolean
  ) {
    const style = localStorage.getItem('map-style');
    const opacity = localStorage.getItem('map-opacity');

    this.persist = persist ?? true;

    if (style) {
      this.style.set(style as MapStyleId);
    }

    if (opacity) {
      this.featureOpacity.set(parseInt(opacity));
    }
  }

  private async loadBingMapStyle(): Promise<mapboxgl.Style> {
    const imagerySet = 'Aerial';
    const culture = 'en-GB';
    const d = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${imagerySet}?output=json&uriScheme=https&include=ImageryProviders&key=${environment.bingMapsApiKey}`);
    const data = await d.json();
    const resourceSets = data.resourceSets[0];
    const resources = resourceSets.resources;
    const resource = resources[0];

    const imageUrl: string = resource.imageUrl;
    const imageUrlSubdomains: string[] = resource.imageUrlSubdomains;

    const tiles = imageUrlSubdomains.map(subdomain => {
      return imageUrl.replace('{subdomain}', subdomain)
        .replace('{culture}', culture);
    });

    const minzoom = resource.zoomMin;
    // 20 seems to be the practical max zoom level, so don't go below that
    const maxzoom = Math.min(resource.zoomMax, 20);

    return {
      version: 8,
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      name: 'Bing Maps',
      sources: {
        'bing': {
          type: 'raster',
          tiles: tiles,
          tileSize: 256,
          minzoom,
          maxzoom
        }
      },
      layers: [{
        id: 'bing',
        type: 'raster',
        source: 'bing'
      }]
    };
  }

  async getStyle(styleId: MapStyleId): Promise<mapboxgl.Style | string> {
    if (styleId === 'bing') {
      return this.loadBingMapStyle();
    }
    return styles[styleId];
  }

  async setStyle(styleId: MapStyleId) {
    this.styleLoaded.set(false);
    const style = await this.getStyle(styleId);
    this.style.set(styleId);
    this.map()!.setStyle(style);
    if (this.persist) {
      localStorage.setItem('map-style', styleId as string);
    }

  }

  async createMap(options: mapboxgl.MapboxOptions) {
    const style = await this.getStyle(this.style());
    const map = new mapboxgl.Map({
      ...options,
      accessToken: environment.mapboxApiKey,
      style
    });

    // Setup events we want to handle
    map.on('styleimagemissing', (e) => {
      if (e.id === 'tree') {
        return map.loadImage('./assets/icons/tree.png', (error, image: any) => {
          if (error) {
            throw error;
          }
          if (!map.hasImage('tree')) {
            map.addImage('tree', image);
            console.log('Added tree image');
          }
        });
      }

      const [code, zoom] = e.id.split('-');
      map.addImage(e.id, this.generateSymbology(code, parseInt(zoom)));
    });

    map.on('style.load', () => {
      this.styleLoaded.set(true);
    });

    this.map.set(map);
    return map;
  }

  treeFilter(filter: mapboxgl.Expression, withTrees: boolean = true) {
    const b = this.geometryTypeFilter('Point', filter, ['baseline', 'pre-survey']);
    b.push([withTrees ? '==' : '!=', ['get', 'metBrdHab'], 'Individual trees']);
    return b;
  }


  geometryTypeFilter(type: 'Polygon' | 'LineString' | 'Point', filter: mapboxgl.Expression, types: string[] = []) {
    const f = ['all', ['==', ['geometry-type'], type], filter];
    if (types.length > 0) {
      const tf: mapboxgl.Expression = ['any']
      for (const t of types) {
        tf.push(['==', ['get', '__type'], ['literal', t]]);
      }
      f.push(tf);
    }
    return f;
  }

  applyFilter(filter: mapboxgl.Expression) {
    const map = this.map()!;
    const pointFilter = this.treeFilter(filter, false);
    const treeFilter = this.treeFilter(filter, true);

    map.setFilter('polygons', this.geometryTypeFilter('Polygon', filter));
    map.setFilter('polygons-outline', this.geometryTypeFilter('Polygon', filter));
    map.setFilter('lines', this.geometryTypeFilter('LineString', filter));
    map.setFilter('lines-dashes', this.geometryTypeFilter('LineString', filter));
    map.setFilter('points', pointFilter);
    map.setFilter('trees', treeFilter);
    map.setFilter('notes', this.geometryTypeFilter('Point', filter, ['species-feature-notes']));
  }

  applyState(selectedIds: (number | string)[], editingId?: number | string) {
    const map = this.map()!;
    map.removeFeatureState({
      source: this.DATA_SOURCE_NAME
    });

    for (const id of selectedIds) {
      map.setFeatureState({
        source: this.DATA_SOURCE_NAME,
        id
      }, {
        selected: true
      });
    }

    if (editingId) {
      map.setFeatureState({
        source: this.DATA_SOURCE_NAME,
        id: editingId
      }, {
        editing: true
      });
    }
  }

  getCurrentBounds() {
    return this.map()!.getBounds();
  }

  updateFeatures(map: mapboxgl.Map, features: FeatureCollection) {
    const source = map.getSource(this.DATA_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(features);
    }
  }

  loadMapLayers(map: mapboxgl.Map, features: FeatureCollection, filter: mapboxgl.Expression, boundary: Feature<Geometry> | null) {

    const source = map.getSource(this.DATA_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(features);
      return this.applyFilter(filter);
    }

    const polygonFilter = this.geometryTypeFilter('Polygon', filter);
    const lineFilter = this.geometryTypeFilter('LineString', filter);
    // const pointFilter = this.geometryTypeFilter('Point', filter, ['baseline', 'pre-survey']);
    const pointFilter = this.treeFilter(filter, false);
    const treeFilter = this.treeFilter(filter, true);

    map.addSource(this.DATA_SOURCE_NAME, {
      type: 'geojson',
      data: features
    });

    map.addSource(this.REDLINE_SOURCE_NAME, {
      type: 'geojson',
      data: boundary ?? emptyFeatureCollection()
    });

    map.addSource(this.DATA_LAYERS_SOURCE, {
      type: 'vector',
      url: 'mapbox://natapp.ukhab-portal'
    });

    for (const layer of this.dataLayers()) {
      map.addLayer(
        {
          'id': layer.id,
          'type': 'fill',
          'source': this.DATA_LAYERS_SOURCE,
          'source-layer': layer.id,
          layout: {
            visibility: layer.active ? 'visible' : 'none'
          },
          paint: {
            'fill-opacity': 0.6,
            'fill-outline-color': '#fff',
            'fill-color': layer.color
          }
        }
      );
    }

    map.addLayer({
      id: this.REDLINE_SOURCE_NAME,
      source: this.REDLINE_SOURCE_NAME,
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-width': 3
      }
    });

    map.addLayer({
      id: 'polygons',
      type: 'fill',
      source: this.DATA_SOURCE_NAME,
      filter: polygonFilter,
      paint: {
        'fill-pattern': [
          "step",
          ["zoom"],
          ["concat", ["get", "ukHabPCode"], "-0"],
          1, ["concat", ["get", "ukHabPCode"], "-1"],
          2, ["concat", ["get", "ukHabPCode"], "-2"],
          3, ["concat", ["get", "ukHabPCode"], "-3"],
          4, ["concat", ["get", "ukHabPCode"], "-4"],
          5, ["concat", ["get", "ukHabPCode"], "-5"],
          6, ["concat", ["get", "ukHabPCode"], "-6"],
          7, ["concat", ["get", "ukHabPCode"], "-7"],
          8, ["concat", ["get", "ukHabPCode"], "-8"],
          9, ["concat", ["get", "ukHabPCode"], "-9"],
          10, ["concat", ["get", "ukHabPCode"], "-10"],
          11, ["concat", ["get", "ukHabPCode"], "-11"],
          12, ["concat", ["get", "ukHabPCode"], "-12"],
          13, ["concat", ["get", "ukHabPCode"], "-13"],
          14, ["concat", ["get", "ukHabPCode"], "-14"],
          15, ["concat", ["get", "ukHabPCode"], "-15"],
          16, ["concat", ["get", "ukHabPCode"], "-16"],
          17, ["concat", ["get", "ukHabPCode"], "-17"],
          18, ["concat", ["get", "ukHabPCode"], "-18"],
          19, ["concat", ["get", "ukHabPCode"], "-19"],
          20, ["concat", ["get", "ukHabPCode"], "-20"]
        ],
        'fill-opacity': fillOpacity(this.featureOpacity())
      }
    });

    map.addLayer({
      id: 'polygons-outline',
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      filter: polygonFilter,
      paint: {
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          4,
          ['boolean', ['feature-state', 'selected'], false],
          3,
          1
        ]
      }
    });

    map.addLayer({
      id: 'lines',
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      filter: lineFilter,
      paint: {
        'line-color': lineColors,
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          5,
          ['boolean', ['feature-state', 'selected'], false],
          4,
          3
        ]
      }
    });

    map.addLayer({
      id: 'lines-dashes',
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      filter: [...lineFilter, ['in', ['get', 'ukHabPCode'], ['literal', ['h2', 'h2a', 'h2b', 'f2d', 'r1e', 'u1e', 'w1g6', 'g3c']]]],
      paint: {
        'line-color': lineDashColors,
        'line-dasharray': [2, 4],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          5,
          ['boolean', ['feature-state', 'selected'], false],
          4,
          3
        ]
      }
    });

    map.addLayer({
      id: 'points',
      type: 'circle',
      source: this.DATA_SOURCE_NAME,
      filter: pointFilter,
      paint: {
        'circle-color': symbologyColors,
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          9,
          ['boolean', ['feature-state', 'selected'], false],
          7,
          5
        ]
      }
    });

    map.addLayer({
      id: 'trees',
      type: 'symbol',
      source: this.DATA_SOURCE_NAME,
      filter: treeFilter,
      layout: {
        'icon-image': 'tree',
        "icon-size": ['interpolate', ['linear'], ['zoom'], 12, 0.001, 20, 0.1],
        'icon-ignore-placement': true,
        'icon-allow-overlap': true
      }
    });

    map.addLayer({
      id: 'notes',
      type: 'circle',
      source: this.DATA_SOURCE_NAME,
      filter: this.geometryTypeFilter('Point', filter, ['species-feature-notes']),
      paint: {
        'circle-color': '#002Aff',
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          9,
          ['boolean', ['feature-state', 'selected'], false],
          7,
          5
        ]
      }
    });



    map.addSource('overlaps', {
      type: 'geojson',
      data: emptyFeatureCollection()
    });

    map.addLayer({
      id: 'overlaps',
      type: 'fill',
      paint: {
        'fill-color': '#ff0000',
        'fill-opacity': 0.6
      },
      source: 'overlaps'
    });
    map.addLayer({
      id: 'overlaps-outline',
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-width': 2
      },
      source: 'overlaps'
    });
  }

  updateRedLineBoundary(map: mapboxgl.Map, boundary: Feature<Geometry> | null) {
    const source = map.getSource(this.REDLINE_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(boundary ?? emptyFeatureCollection());
    }
  }

  setOverlaps(overlaps: FeatureCollection) {
    const source = this.map()!.getSource('overlaps') as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(overlaps);
    }
  }

  toggleLayer(layer: UKHabDataLayer) {
    const layers = this.dataLayers();
    const index = layers.findIndex(l => l.id === layer.id);
    const newLayers = [...layers];
    newLayers[index] = { ...layer, active: !layer.active };
    this.dataLayers.set(newLayers);
    this.map()!.setLayoutProperty(layer.id, 'visibility', layer.active ? 'none' : 'visible');
  }

  toggleAllLayers(on: boolean) {
    const layers = this.dataLayers();
    let layersToToggle;
    if (on) {
      // toggle all layers on that aren't already
      layersToToggle = layers.filter(l => !l.active);
    } else {
      // toggle all layers off that aren't already
      layersToToggle = layers.filter(l => l.active);
    }
    layersToToggle.forEach(l => this.toggleLayer(l));
  }

  // toggleRedlineBoundary() {
  //   const visible: boolean = this.map()!.getLayoutProperty(
  //     this.REDLINE_SOURCE_NAME,
  //     'visibility'
  //   ) === 'visible';
  //   this.map()!.setLayoutProperty(this.REDLINE_SOURCE_NAME, 'visibility', visible ? 'none' : 'visible');
  // }

  generateSymbology = (id: string, zoom: number) => {
    const size = 20;
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');

    const config = ukHabSybmology.find(([key]) => id.startsWith(key))?.[1];
    if (!config) {
      console.warn('Missing symbology config for ', id);
      ctx!.fillStyle = '#BBBBBB';
      ctx!.fillRect(0, 0, size, size);
      return ctx!.getImageData(0, 0, canvas.width, canvas.height);
    }

    if (typeof config === 'string') {
      ctx!.fillStyle = config;
      ctx!.fillRect(0, 0, size, size);
      return ctx!.getImageData(0, 0, canvas.width, canvas.height);
    }

    const center = size / 2;
    ctx!.fillStyle = config.backgroundColor ?? '#FFFFFF';
    ctx!.fillRect(0, 0, size, size);
    ctx!.fillStyle = ctx!.strokeStyle = config.color;
    ctx!.beginPath();

    switch (config.type) {
      case 'stripes': {
        const angle = config.angle ?? 0;
        const length = config.dash ? size/2 : size*Math.sqrt(2);
        ctx!.lineWidth = size / config.lineWidth;
        ctx!.save();

        // if 135deg diagonal
        angle === 135 && ctx!.translate(size, 0);
        // draw main line
        ctx!.rotate((angle)* Math.PI/180);
        ctx!.moveTo(0, 0); ctx!.lineTo(length, 0);
        ctx!.restore();
        // 45deg 2-side-lines
        if (angle === 45) {
          ctx!.moveTo(-size, 0); ctx!.lineTo(size, 2*size);
          ctx!.moveTo(0, -size); ctx!.lineTo(2*size, size);
        }
        // 135deg 2-side-lines
        if (angle === 135) {
          ctx!.moveTo(2*size, 0); ctx!.lineTo(0, 2*size);
          ctx!.moveTo(size, -size); ctx!.lineTo(-size, size);
        }
        ctx!.stroke();
        break;
      }
      case 'dots': {
        const radius = size/Math.sqrt(2)/4;
        // 5 circles
        const offsets = [[0.5,0.5],[0.5,1.5],[1.5,0],[1.5,1],[1.5,2]];
        offsets.forEach(([offsetX, offsetY]) => {
          ctx!.beginPath();
          ctx!.arc(offsetX*center, offsetY*center, radius, 0, 2 * Math.PI, false);
          ctx!.fill();
          ctx!.stroke();
        });

        // ONE CIRCLE
        // ctx!.beginPath();
        // ctx!.arc(center, center, radius, 0, 2 * Math.PI, false);
        // ctx!.fill();
        break;
      }
      case 'rhombs': {
        // 4 RHOMBs
        const offsets = [[0.5,0],[0.5,1],
          ... config.border ? [[1.5,0]] : [],
          ... config.border ? [[1.5,1]] : []
        ];
        offsets.forEach(([offsetX, offsetY]) => {
          ctx!.save();
          ctx!.translate(center*offsetX, center*offsetY);
          ctx!.rotate(45 * Math.PI/180);
          const rectMethod = config.border ? 'rect' : 'fillRect';
          ctx![rectMethod](0, 0, size/(2*Math.sqrt(2)), size/(2*Math.sqrt(2)));
          ctx!.restore();
        });
        ctx!.stroke();

        // ONE RHOMB
        // ctx!.save();
        // ctx!.translate(center, 0);
        // ctx!.rotate(45 * Math.PI/180);
        // ctx!.fillRect(0, 0, size/Math.sqrt(2), size/Math.sqrt(2));
        // ctx!.restore();
        break;
      }
    }

    // Debug square
    // ctx!.beginPath();
    // ctx!.strokeStyle = '#FF0000';
    // ctx!.strokeRect(0, 0, size, size);

    // ctx!.stroke();

    return ctx!.getImageData(0, 0, canvas.width, canvas.height);
  }

  switchControl() {
    const control = new SwitchMapControl();
    return control;
  }


  openSettings() {
    this.settingsOpen.set(true);
  }

  closeSettings() {
    this.settingsOpen.set(false);
  }

  setFeatureOpacity(pct: number) {
    this.featureOpacity.set(pct);
    this.map()!.setPaintProperty('polygons', 'fill-opacity', fillOpacity(pct));
  }

  fitBounds(bounds: mapboxgl.LngLatBoundsLike, options?: mapboxgl.FitBoundsOptions) {
    this.map()!.fitBounds(bounds, options);
  }

  resizeObservable(elem: HTMLElement) {
    return new Observable(subscriber => {
      var ro = new ResizeObserver(entries => {
        subscriber.next(entries);
      });

      // Observe one or multiple elements
      ro.observe(elem);
      return function unsubscribe() {
        ro.unobserve(elem);
      }
    });
  }

  getGlobalMaskLayer = (bounds: MultiPolygon | Polygon) => {
    const globalLayer = polygon([[
      [-180, -90],
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90]
    ]]);
    const appLayer = featureHelper(bounds);
    return difference(globalLayer, appLayer);
  }
}


class SwitchMapControl implements mapboxgl.IControl {
  private container: HTMLDivElement | undefined;
  private button: HTMLButtonElement | undefined;
  private map: mapboxgl.Map;

  constructor(private initialStyle = mapboxStreetsStyle) {
    this.map = null as any;
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    const container = this.container = document.createElement('div');
    container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group');

    const button = this.button = document.createElement('button');
    const initialClass = this.initialStyle === mapboxStreetsStyle ? 'satellite' : 'streets';
    button.classList.add('mapboxgl-ctrl-switch', initialClass);

    button.addEventListener('click', this.handleClick);
    container.appendChild(button);
    return container;
  }

  handleClick = () => {
    this.map.fire('switchmap')
    this.button!.classList.toggle('streets');
    this.button!.classList.toggle('satellite');
  }

  onRemove(_map: mapboxgl.Map): void {
    this.button?.removeEventListener('click', this.handleClick);
    this.container?.remove();
    this.container = undefined;
  }
}

interface MapSettingsHost {
  openSettings(): void;
}
export class MapSettingsControl implements mapboxgl.IControl {

  private container: HTMLDivElement | undefined;
  private button: HTMLButtonElement | undefined;

  constructor(private component: MapSettingsHost) { }

  onAdd(): HTMLElement {
    const container = this.container = document.createElement('div');
    container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group', 'mapboxgl-ctrl-map-settings');

    const button = this.button = document.createElement('button');
    button.classList.add('mapboxgl-ctrl-settings');

    const icon = document.createElement('img');
    icon.src = '/assets/icons/layers.svg';
    icon.style.width = '100%';
    icon.style.height = '100%';
    icon.style.padding = '4px';

    button.appendChild(icon);

    button.addEventListener('click', this.handleClick);
    container.appendChild(button);
    return container;
  }

  handleClick = () => {
    this.component.openSettings();
  }

  onRemove(_map: mapboxgl.Map): void {
    this.button?.removeEventListener('click', this.handleClick);
    this.container?.remove();
    this.container = undefined;
  }
}



export class MapboxBoxSelectControl implements mapboxgl.IControl {
  private container: HTMLDivElement | undefined;
  private mapContainer: HTMLElement | undefined;
  private map: mapboxgl.Map | undefined;
  private start: mapboxgl.Point;
  private current: mapboxgl.Point;
  private box: HTMLDivElement | null;

  private enabled: boolean = false;

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.container = document.createElement('div');
    this.mapContainer = map.getCanvasContainer();
    this.mapContainer.addEventListener('mousedown', this.handleCanvasMouseDown, true);
    return this.container;
  }

  handleCanvasMouseDown = (e: MouseEvent) => {
    if (!(e.shiftKey && e.button === 0) || !this.enabled) {
      return;
    }

    this.map!.dragPan.disable();
    this.map!.dragRotate.disable();

    document.addEventListener('mousemove', this.handleCanvasMouseMove);
    document.addEventListener('mouseup', this.handleCanvasMouseUp);
    document.addEventListener('keydown', this.handleCanvasKeyDown);

    this.start = this.getMousePos(e);
    this.map?.fire('boxselectstart');
  }

  handleCanvasMouseMove = (e: MouseEvent) => {
    this.current = this.getMousePos(e);

    if (!this.box) {
      this.box = document.createElement('div');
      this.box.classList.add('boxdraw');
      this.mapContainer!.appendChild(this.box);
    }

    const minX = Math.min(this.start.x, this.current.x);
    const maxX = Math.max(this.start.x, this.current.x);
    const minY = Math.min(this.start.y, this.current.y);
    const maxY = Math.max(this.start.y, this.current.y);

    const pos = `translate(${minX}px, ${minY}px)`;
    this.box.style.transform = pos;
    this.box.style.width = `${maxX - minX}px`;
    this.box.style.height = `${maxY - minY}px`;
  }

  handleCanvasMouseUp = (e: MouseEvent) => {
    this.finishBox([this.start, this.getMousePos(e)]);
  }

  handleCanvasKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.finishBox();
    }
  }

  finishBox(bbox?: [mapboxgl.PointLike, mapboxgl.PointLike]) {
    document.removeEventListener('mousemove', this.handleCanvasMouseMove);
    document.removeEventListener('mouseup', this.handleCanvasMouseUp);
    document.removeEventListener('keydown', this.handleCanvasKeyDown);

    if (this.box) {
      this.box.parentNode?.removeChild(this.box);
      this.box = null;
    }

    if (bbox) {
      this.map?.fire('boxselect', { bbox });
    }
    this.map!.dragPan.enable();
    this.map?.fire('boxselectend');
  }

  getMousePos(e: MouseEvent) {
    const rect = this.mapContainer!.getBoundingClientRect();
    return new mapboxgl.Point(e.clientX - rect.left - this.map!.getCanvas().clientLeft, e.clientY - rect.top - this.map!.getCanvas().clientTop);
  }


  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.container = undefined;
    this.map = undefined;
    this.mapContainer = undefined;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
  }
}
