import { inject } from "@angular/core";
import { ResolveFn, Router } from "@angular/router";
import { Project } from "./core/models/project.model";
import { ProjectsService } from "./core/services/projects.service";
import { mergeMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { OrganisationsService } from "./core/services/organisations.service";
import { ProjectService } from "./features/project/project.service";

export const projectResolver: ResolveFn<Project> = (route) => {
  const projectsService = inject(ProjectsService);
  const projectService = inject(ProjectService);
  const organisationService = inject(OrganisationsService);
  const router = inject(Router);

  const projectId = parseInt(route.paramMap.get("id")!);

  const project = projectService.project();
  if (project !== null && project.id === projectId) {
    projectsService.currentProject.set(project);
    return of(project);
  }

  return projectsService.get(projectId).pipe(mergeMap(project => {
    if (project) {
      projectService.project.set(project);
      organisationService.setOrganisation(project.organisationId);
      return of(project);
    }
    router.navigate(['/']);
    return EMPTY;
  }));
};
