import { Injectable, signal, computed, inject } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { User } from "../models/user.model";
import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import * as Sentry from "@sentry/angular-ivy";
@Injectable({ providedIn: 'root' })
export class UserService {

  private apiService = inject(ApiService);
  private jwtService = inject(JwtService);
  private router = inject(Router);

  public currentUser = signal<User | null>(null);
  public isAuthenticated = computed<boolean>(() => !!this.currentUser());
  public userImage = computed<string>(() => this.currentUser()?.imageUrl ?? '/assets/icons/user.svg');

  login(email: string, password: string): Observable<User> {
    const query = `mutation UKHabPortalLogin($email: String!, $password: String!) {
      login(input: { email: $email, password: $password } ) {
        user{
          id
          name: displayName
          image: imageUrl
        }
        token
      }
    }`;
    return this.apiService
      .graphql<{ login: { token: string; user: User } }>(query, { email, password })
      .pipe(
        tap(result => this.setAuth(result.login.token, result.login.user)),
        map(result => result?.login?.user)
      );
  }

  logout(): void {
    this.purgeAuth();
    this.router.navigateByUrl('/login');
  }

  setAuth(token: string, user: User): void {
    this.jwtService.setToken(token);
    Sentry.setUser({
      email: user.email,
      id: user.id.toString(),
      username: user.username
    });
    this.currentUser.set(user);
  }

  purgeAuth(): void {
    this.jwtService.destroyToken();
    Sentry.setUser(null);
    this.currentUser.set(null);
  }

}
