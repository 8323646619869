import { z } from "zod";

export const PreSurveySchema = z
  .object({
    id: z.number(),
    geometry: z.any(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
    UKHab_L2: z.string().optional().nullable(),
    Notes: z.string().optional().nullable(),
    Essential: z.string().array().optional(),
    Additionals: z.string().array().optional(),
    area: z.number().optional(),
    length: z.number().optional()
  })
  .strict();
export type PreSurvey = z.infer<typeof PreSurveySchema>;

export type CreateGeoType = 'Polygon' | 'LineString' | 'Point';
