import { z } from 'zod';

export const OrganisationSchema = z.object({
  id: z.number(),
  name: z.string(),
  role: z.string(),
  tier: z.number(),
  bing: z.boolean()
})
  .strict();

export type Organisation = z.infer<typeof OrganisationSchema>;
