import { Injectable, computed, effect, inject, signal } from "@angular/core";
import { NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { Project, ProjectUpdate } from "src/app/core/models/project.model";
import { HttpClient } from "@angular/common/http";
import { AllGeoJSON, MultiPolygon, area, bbox, clone, length } from "@turf/turf";
import { Observable, Subject, firstValueFrom, forkJoin, from, fromEvent, map, mergeMap, takeUntil, toArray } from "rxjs";
import slufigy from "slugify";
import { Species, SpeciesSample, Survey, SurveyLog } from "src/app/core/models/survey.model";
import { ApiService } from "src/app/core/services/api.service";
import { emptyFeatureCollection } from "src/app/core/services/maps.service";
import { environment } from "src/environments/environment";

export type HabitatFilter = Record<UKHabL2Code, boolean | null>;
export type GeometryFilter = Record<Geometry['type'], boolean | null>;

const emptyHabitatFilter: HabitatFilter = {
  g: false,
  f: false,
  c: false,
  h: false,
  r: false,
  s: false,
  t: false,
  u: false,
  w: false
};

const emptyGeometryFilter: GeometryFilter = {
  Point: false,
  MultiPoint: false,
  LineString: false,
  MultiLineString: false,
  Polygon: false,
  MultiPolygon: false,
  GeometryCollection: false
};

export type METRIC_VERSION = 'statutory' | '4.0';

export type SurveyFeatureProperties = {
  [P in keyof Omit<Survey, 'images' | 'type' | 'userId' | 'species' | 'samples' | 'state' | 'logs'>]: string | undefined | number;
} & {
  id: number | string;
  l2?: string;
}

const views = [
  'baseline',
  'pre-survey',
  'species-feature-notes'
] as const;

type VIEW = typeof views[number];

export const ukhabL2Codes = ["g", "f", "c", "h", "r", "s", "t", "u", "w"] as const;
export type UKHabL2Code = typeof ukhabL2Codes[number];
type OverlapsFeatureCollection = FeatureCollection<Polygon | MultiPolygon, { aId: number | string; bId: number | string; aName: string; bName: string; }>;

interface SurveyResponse {
  id: number;
  samples: SpeciesSample[];
  species: Species[];
  speciesResult: {
    spsTotal: number;
    spsPerQ: number;
    nativeWSps: number;
    qRecorded: number;
  }
}

@Injectable()
export class ProjectService {

  router = inject(Router);
  httpClient = inject(HttpClient);
  apiService = inject(ApiService);

  public readonly project = signal<Project | null>(null);
  public readonly users = computed(() => this.project()?.users ?? []);
  public readonly baseline = computed(() => this.project()?.surveys ?? []);
  public readonly presurveys = computed(() => this.project()?.presurveys ?? []);
  public readonly speciesFeatureNotes = computed(() => this.project()?.speciesFeatureNotes ?? []);
  public readonly isV3 = computed(() => {
    return this.project()?.ukHabVersion === 'ukhabappv3';
  });
  public readonly slug = computed(() => {
    const project = this.project();
    return slufigy(project?.name ?? 'ukhab', {
      lower: true,
      trim: true,
      strict: true
    });
  });
  public readonly isAdmin = computed(() => this.project()?.role === 'admin');

  public readonly view = signal<VIEW>('baseline');
  public readonly sidebarOpen = signal<boolean>(false);
  public readonly mapMaximized = signal<boolean>(false);

  public readonly isMapEditing = signal<boolean>(false);

  public readonly selectedIds = signal<number[]>([]);
  public readonly selectedSource = signal<'map' | 'table' | null>(null);

  public readonly hoveredId = signal<[number | null, number | null]>([null, null]);
  public readonly currentHoveredId = computed(() => this.hoveredId()[0]);

  public readonly activeBaselineId = signal<number | null>(null);
  public readonly activeBaseline = computed(() => {
    const id = this.activeBaselineId();
    return this.baseline().find(s => s.id === id);
  });

  private readonly geometryEdits = signal<Record<number, Geometry>>({});
  public readonly hasEdits = computed(() => {
    return Object.keys(this.geometryEdits()).length > 0;
  });

  public readonly idFilter = signal<number | null>(null);
  public readonly habitatFilter = signal<HabitatFilter>({ ...emptyHabitatFilter });
  public readonly geometryFilter = signal<GeometryFilter>({ ...emptyGeometryFilter });

  public readonly baselineFiltered = computed(() => {
    const id = this.idFilter();
    const habitatFilter = this.habitatFilter();
    const geometryFilter = this.geometryFilter();
    const baseline = this.baseline();

    if (id) {
      return baseline.filter(s => s.id === id);
    }

    const habitats = Object.keys(habitatFilter).filter(key => habitatFilter[key as UKHabL2Code] === true);
    const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

    if (!habitats.length && !geometry.length) return baseline;

    return baseline.filter(s => {
      const code = s.ukHabPCode?.[0] as UKHabL2Code;
      const type = s.geometry?.type as Geometry['type'];
      return (
        (!habitats.length || habitats.includes(code)) &&
        (!geometry.length || geometry.includes(type))
      );
    });
  });

  public readonly baselineSelected = computed(() => {
    const selected = this.selectedIds();
    return this.baseline().filter(s => selected.includes(s.id));
  });

  public readonly features = computed<FeatureCollection<Geometry, any>>(() => {
    const project = this.project();
    if (!project) {
      return this.emptyFeatureCollection();
    }

    const features: Feature<Geometry, any>[] = [];

    for (const survey of project.surveys.filter(g => !!g.geometry)) {
      features.push({
        type: 'Feature',
        geometry: survey.geometry,
        id: survey.id,
        properties: {
          __type: 'baseline',
          id: survey.id ?? '',
          area: survey.area ?? 0,
          createdAt: survey.createdAt ?? '',
          updatedAt: survey.updatedAt ?? '',
          length: survey.length ?? '',
          ukHabPCode: survey.ukHabPCode ?? '',
          ukHabPName: survey.ukHabPName ?? '',
          ukHabCCode: survey.ukHabCCode ?? '',
          conNotes: survey.conNotes ?? '',
          conCrit: survey.conCrit ?? '',
          conScore: survey.conScore ?? '',
          conResult: survey.conResult ?? '',
          metBUnits: survey.metBUnits ?? '',
          metBrdHab: survey.metBrdHab ?? '',
          metHUnits: survey.metHUnits ?? '',
          metHab: survey.metHab ?? '',
          metHabType: survey.metHabType ?? '',
          metMsg: survey.metMsg ?? '',
          spsTotal: survey.spsTotal ?? '',
          spsPerQ: survey.spsPerQ ?? '',
          nativeWSps: survey.nativeWSps ?? '',
          qRecorded: survey.qRecorded ?? '',
          ukHabS1Cod: survey.ukHabS1Cod ?? '',
          ukHabS1Nam: survey.ukHabS1Nam ?? '',
          ukHabS2Cod: survey.ukHabS2Cod ?? '',
          ukHabS2Nam: survey.ukHabS2Nam ?? '',
          ukHabS3Cod: survey.ukHabS3Cod ?? '',
          ukHabS3Nam: survey.ukHabS3Nam ?? '',
          ukHabS4Cod: survey.ukHabS4Cod ?? '',
          ukHabS4Nam: survey.ukHabS4Nam ?? '',
          ukHabS5Cod: survey.ukHabS5Cod ?? '',
          ukHabS5Nam: survey.ukHabS5Nam ?? '',
          ukHabS6Cod: survey.ukHabS6Cod ?? '',
          ukHabS6Nam: survey.ukHabS6Nam ?? '',
          l2: survey.ukHabPCode?.slice(0, 1),
          featName: survey.featName ?? '',
          stratSig: survey.stratSig ?? ''
        }
      });
    }

    for (const presurvey of project.presurveys.filter(g => !!g.geometry)) {
      features.push({
        type: 'Feature',
        id: presurvey.id,
        geometry: presurvey.geometry,
        properties: {
          ...presurvey,
          __type: 'pre-survey',
          ukHabPCode: presurvey.UKHab_L2 ?? ''
        }
      })
    }

    for (const sfn of (project.speciesFeatureNotes ?? []).filter(g => !!g.geometry)) {
      features.push({
        type: 'Feature',
        id: sfn.id,
        geometry: sfn.geometry,
        properties: {
          ...sfn,
          __type: 'species-feature-notes',
        }
      })
    }

    return {
      type: 'FeatureCollection',
      features
    };
  });

  public readonly featuresWithEdits = computed(() => {
    const featuresWithEdits: FeatureCollection<Geometry, any> = clone(this.features() as AllGeoJSON);
    for (const [id, geometry] of Object.entries(this.geometryEdits())) {
      const idx = featuresWithEdits.features.findIndex(f => f.id === +id);
      if (idx === -1) {
        console.warn('Could not find edited feature??');
        continue;
      }
      featuresWithEdits.features[idx].geometry = geometry;
    }
    return featuresWithEdits;
  });

  public readonly baselineWithEdits = computed(() => {
    const features: FeatureCollection<Geometry, any> = clone(this.featuresWithEdits() as AllGeoJSON);
    return {
      ...features,
      features: features.features.filter(f => f.properties.__type === 'baseline')
    }
  });

  public readonly featuresBoundingBox = computed(() => {
    const features = this.features();
    if (!features.features.length) {
      return null;
    }
    return bbox(features) as mapboxgl.LngLatBoundsLike;
  });

  public readonly viewFeaturesBoundingBox = computed(() => {
    const features = this.features().features.filter(f => f.properties.__type === this.view())
    if (!features.length) {
      return null;
    }
    return bbox({ type: 'FeatureCollection', features }) as mapboxgl.LngLatBoundsLike;
  });

  public readonly geometryTypes = computed(() => {
    const types: Geometry['type'][] = [];
    for (const record of this.baseline() ?? []) {
      if (record.geometry?.type && !types.includes(record.geometry.type)) {
        types.push(record.geometry.type);
      }
    }
    return types;
  });

  public readonly hoveredFeature = computed(() => {
    const id = this.currentHoveredId();
    if (!id) {
      return null;
    }
    return this.features().features.find(f => f.id === id);
  });

  public readonly redLineBoundary = computed<Feature<Polygon | MultiPolygon> | null>(() => {
    const project = this.project();
    if (!project || !project.geometry) {
      return null;
    }
    return {
      type: 'Feature',
      geometry: project.geometry,
      properties: {}
    };
  });

  public readonly redLineBoundaryBox = computed(() => {
    const boundary = this.redLineBoundary();
    if (!boundary) {
      return null;
    }
    return bbox(boundary) as mapboxgl.LngLatBoundsLike;
  });

  public readonly mapFilter = computed<mapboxgl.Expression>(() => {
    const idFilter = this.idFilter();
    const habitatFilter = this.habitatFilter();
    const geometryFilter = this.geometryFilter();
    const view = this.view();

    const expression: mapboxgl.Expression = ['all', ['==', ['get', '__type'], ['literal', view]]];

    if (idFilter) {
      return ['==', ['id'], ['literal', idFilter]] as mapboxgl.Expression;;
    }

    const habitats = Object.keys(habitatFilter).filter(key => habitatFilter[key as UKHabL2Code] === true);
    const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

    if (!habitats.length && !geometry.length) {
      return expression;
    }

    if (geometry.length > 0) {
      expression.push(['in', ['geometry-type'], ['literal', geometry]]);
    }

    if (habitats.length > 0) {
      expression.push(['in', ['get', 'l2'], ['literal', habitats]])
    }
    return expression;
  });

  public readonly overlaps = signal<OverlapsFeatureCollection>({
    type: 'FeatureCollection',
    features: []
  });

  public readonly showOverlaps = signal<boolean>(false);

  public sigs: {id: string, name: string}[] = [
    { id: 'low_significance', name: 'Low (1.0)' },
    { id: 'medium_significance', name: 'Medium (1.1)' },
    { id: 'high_significance', name: 'High (1.15)' },
  ] as const;

  private sidebarUrls: string[] = [
    'sidebar:activity',
    'sidebar:filter',
    'sidebar:geometry',
    'sidebar:stats',
  ];

  private worker: Worker;
  destroy$ = new Subject<void>();


  constructor() {

    this.worker = new Worker(new URL('./project.worker', import.meta.url));

    fromEvent<any>(this.worker, 'message').pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ data }) => {
      this.overlaps.set(JSON.parse(data));
    });

    effect(() => {
      const features = this.baselineWithEdits();
      const showOverlaps = this.showOverlaps();
      this.worker.postMessage(JSON.stringify(showOverlaps ? features : emptyFeatureCollection()));
    });

    this.handleRouting(this.router.url);
    this.router.events.subscribe(t => {
      if (t instanceof NavigationEnd) {
        this.handleRouting(t.url);
      }
    });
  }

  ngOnDestroy() {
    this.worker.terminate();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateView(view: VIEW) {
    this.clearSelected();
    this.geometryEdits.set({});
    this.isMapEditing.set(false);
    this.view.set(view);
  }

  handleRouting(url: string) {
    const g = this.router.parseUrl(url);
    const s = g.root.children[PRIMARY_OUTLET];
    if (s) {
      const view = s.segments[s.segments.length - 1].path;
      this.clearSelected();
      if (views.includes(view as VIEW)) {
        this.updateView(view as VIEW);
      }
    }
    this.sidebarOpen.set(this.sidebarUrls.some(u => url.includes(u)));
  }

  toggleMapMaximized() {
    this.mapMaximized.update(maximized => !maximized);
  }

  public setHoveredId(id: number | null) {
    const current = this.currentHoveredId();
    if (current === id) {
      return;
    }
    this.hoveredId.set([id, current]);
  }

  public select(id: number | number[], source: 'map' | 'table', isShiftPressed: boolean = false) {
    this.selectedSource.set(source);

    if (!isShiftPressed || Array.isArray(id)) {
      return this.selectedIds.set(Array.isArray(id) ? id : [id]);
    }
    const ids = this.selectedIds();
    const selectedIds = ids.includes(id) ? ids.filter(id => id !== id) : [...ids, id];
    this.selectedIds.set([...selectedIds]);
  }

  public toggleSidebar() {
    this.sidebarOpen.update(open => !open);
  }

  public getSelectedIds() {
    return this.selectedIds();
  }

  public clearSelected() {
    this.selectedIds.set([]);
    this.selectedSource.set(null);
  }

  public selectAll() {
    const view = this.view();
    let ids: number[] = [];
    switch (view) {
      case 'baseline':
        ids = this.baseline().map(s => s.id);
        break;
      case 'pre-survey':
        ids = this.presurveys().map(s => s.id);
        break;
      case 'species-feature-notes':
        ids = this.speciesFeatureNotes().map(s => s.id);
        break;
    }
    this.selectedIds.set(ids);
  }

  public startMapEditing() {
    this.isMapEditing.set(true);
  }

  public setIdFilter(id: number | null) {
    this.clearSelected();
    this.idFilter.set(id);
  }

  public setGeometryFilter(filter: Partial<GeometryFilter>) {
    this.geometryFilter.update(f => ({ ...f, ...filter }));
  }

  public setHabitatFilter(filter: Partial<HabitatFilter>) {
    this.habitatFilter.update(f => ({ ...f, ...filter }));
  }

  private createGeometry(geometry: Geometry) {
    const project = this.project()!;
    const input = {
      geometry,
      properties: {
        surveyId: project.preSurveyFormId,
        projectId: project.id
      }
    };

    return this.httpClient.post<{ id: number; area: number; length: number }>(`${environment.apiUrl}/pre-survey`, input).subscribe((res) => {
      this.project.set({
        ...project,
        presurveys: [
          ...project.presurveys,
          {
            id: res.id,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            geometry,
            UKHab_L2: '',
            area: res.area,
            length: res.length
          }
        ]
      });
    });
  }

  public updateGeometry(updates: Record<number, Geometry>) {
    const project = this.project()!;
    const view = this.view();
    const key: keyof Project = view === 'baseline' ? 'surveys' : 'presurveys';
    const data = project[key].slice(0);
    const obs = [];

    for (const [id, geometry] of Object.entries(updates)) {
      let existingIdx = data.findIndex(s => s.id === +id);
      if (existingIdx === -1) {
        continue;
      }
      let existing = data[existingIdx];
      existing.geometry = geometry;
      if (existing.hasOwnProperty('type')) {
        (existing as any).type = geometry.type;
      }

      if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
        existing.length = 0;
        existing.area = area({
          type: 'Feature',
          geometry,
          properties: {}
        }) / 10000;
      } else if (geometry.type === 'LineString' || geometry.type === 'MultiLineString') {
        existing.area = 0;
        existing.length = length(geometry as any, { units: 'kilometers' });
      }

      if (view === 'baseline') {
        existing = {
          ...existing,
          ...this.calculateUnits(existing as Survey)
        }
      }
      data[existingIdx] = existing;


      obs.push(this.httpClient.put(`${environment.apiUrl}/${key === 'surveys' ? 'survey' : 'pre-survey'}?id=${id}`, { geometry, id }));
    }
    this.project.set({
      ...project,
      [key]: data
    });

    forkJoin(obs).subscribe(() => {
      console.log('HEREdone');
    });
  }

  public updateSurveyGeometry(id: number, geometry: Geometry) {
    const changes = this.geometryEdits();
    changes[id] = geometry;
    this.geometryEdits.set({
      ...changes
    });
  }

  public stopMapEditing(save = false) {
    const changes = this.geometryEdits();

    if (!save) {
      this.geometryEdits.set({});
      this.isMapEditing.set(false);
      return;
    }

    // If we are creating something
    const features = this.features();
    const baseline = this.baseline();

    const updates: Record<number, Geometry> = {};
    for (const [id, geometry] of Object.entries(changes)) {
      const existing = features.features.findIndex(f => f.id === +id);
      if (existing !== -1) {
        updates[+id] = geometry;
      } else {
        // See if it's a baseline with a new geometry
        if (typeof baseline.find(s => s.id === +id) !== 'undefined') {
          updates[+id] = geometry;
        } else {
          this.createGeometry(geometry);
        }
      }
    }
    if (Object.keys(updates).length > 0) {
      this.updateGeometry(updates);
    }
    this.geometryEdits.set({});
    this.isMapEditing.set(false);
  }

  deleteRecords(ids: number[]) {
    if (ids.length === 0) {
      return;
    }
    const view = this.view();
    const project = this.project()!;
    const cascade = view === 'baseline';
    const url = `${environment.apiUrl}/delete?projectId=${project.id}${cascade ? '&cascade=true' : ''}`

    this.httpClient.delete(url, {
      body: ids
    }).subscribe(() => {
      if (view === 'baseline') {
        project.surveys = project.surveys.filter(s => !ids.includes(s.id));
      } else if (view === 'pre-survey') {
        project.presurveys = project.presurveys.filter(s => !ids.includes(s.id));
      } else if (view === 'species-feature-notes') {
        project.speciesFeatureNotes = project.speciesFeatureNotes?.filter(s => !ids.includes(s.id)) ?? [];
      }
      this.clearSelected();
      this.project.set({
        ...project
      });
    });
  }

  deleteSelected() {
    return this.deleteRecords(this.selectedIds());
  }

  updateL2(id: number, l2: string) {
    const project = this.project()!
    const path = project!.presurveyL2AttributePath;

    this.httpClient.put(`${environment.apiUrl}/pre-survey`, {
      id,
      data: {
        [path!]: l2
      }
    }).subscribe(() => {
      const idx = project.presurveys.findIndex(s => s.id === id);
      if (idx === -1) {
        return;
      }
      const presurveys = [
        ...project.presurveys.slice(0, idx),
        { ...project.presurveys[idx], UKHab_L2: l2 },
        ...project.presurveys.slice(idx + 1)
      ];
      this.project.set({ ...project, presurveys });
    });
  }

  updateBaselineFeatureName(id: number, featName: string) {
    return this.updateBaseline([{ id, featName }]);
  }

  updateStrategicSignificance(updates: { id: number, stratSig: string }[]) {
    return this.updateBaseline(updates);
  }

  calculateUnits(survey: Survey) {
    const { conScore, area, length, distinctiv, stratSig } = survey;
    const sigMap = {
      'high_significance': 1.15,
      'medium_significance': 1.1,
      'low_significance': 1.00,
    };

    const sigModifier = sigMap[stratSig as keyof typeof sigMap] ?? 1.0;
    let metHUnits = 0;
    let metBUnits = 0;


    if (conScore && distinctiv) {
      if (area) {
        metBUnits = conScore * area * distinctiv * sigModifier;
      }
      if (length) {
        metHUnits = conScore * length * distinctiv * sigModifier;
      }
    }

    return {
      metBUnits,
      metHUnits
    }

  }

  patchBaseline(updates: Partial<Survey>[]) {
    const project = this.project()!;
    const surveys = project.surveys.slice(0);
    for (const update of updates) {
      const idx = project.surveys.findIndex(s => s.id === update.id);
      const updatedSurvey = {
        ...project.surveys[idx],
        ...update
      };
      surveys[idx] = {
        ...updatedSurvey,
        ...this.calculateUnits(updatedSurvey)
      };
      // Recalculate metric units
    }
    this.project.set({
      ...project,
      surveys
    });
  }

  updateBaseline(updates: Partial<Survey>[]) {
    return this.httpClient.put(`${environment.apiUrl}/survey`, updates).subscribe(() => {
      this.patchBaseline(updates);
    });
  }

  getRecordLogs(recordId: number): Observable<SurveyLog[]> {
    return this.httpClient.get<{ logs: { logs: any[] } }>(`${environment.apiUrl}/logs?recordId=${recordId}`).pipe(
      map((result) => {
        const logs: SurveyLog[] = [];
        for (const log of result.logs?.logs ?? []) {
          logs.push(log);
        }
        logs.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        return logs;
      })
    );
  }

  getProjectLogs(): Observable<SurveyLog[]> {

    const project = this.project()!;
    return this.httpClient.get<any[]>(`${environment.apiUrl}/logs?projectId=${project.id}`).pipe(
      map((result) => {
        const logs: SurveyLog[] = [];
        for (const survey of result) {
          for (const log of survey.logs?.logs ?? []) {
            logs.push(log);
          }
        }
        logs.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        return logs;
      })
    );
  }

  updateProject(updatedProject: ProjectUpdate) {
    const project = this.project()!;
    this.project.set({
      ...project,
      ...updatedProject
    });
  }

  loadBaseline(ids: number[]) {
    const projectId = this.project()!.id;
    const idsString = ids.map(id => `id=${id}`).join('&');
    const url = `${environment.apiUrl}/survey?projectId=${projectId}&${idsString}`;
    return this.httpClient.get<SurveyResponse[]>(url).pipe(
      map(response => {

        for (const r of response) {
          const s = this.baseline().find(s => s.id === r.id);
          this.patchBaseline([
            {
              id: r.id,
              samples: r.samples,
              species: r.species,
              ...r.speciesResult
            }
          ]);
        }
      }));

  }

  loadAllBaseline() {
    const ids = this.baseline().map(s => s.id);
    const batchSize = 10;
    const concurrency = 6;

    // Batch the ids into groups of 10
    const batches = [];
    for (let i = 0; i < ids.length; i += batchSize) {
      batches.push(ids.slice(i, i + batchSize));
    }

    // Load the batches sequentially
    // return forkJoin(batches.map(batch => this.loadBaseline(batch)));
    return from(batches).pipe(
      mergeMap(batch => this.loadBaseline(batch), concurrency),
      toArray());
  }

  updateBaselineStatus(id: number, state: number) {
    const SurveyVerifyRecord = `
      mutation UKHabVerifyRecord($input: RecordVerifyInput!){
        verifyRecord(input: $input)
      }
    `;

    return firstValueFrom(this.apiService.graphql(SurveyVerifyRecord, {
      input: { id, state }
    })).then(() => {
      this.patchBaseline([{
        id,
        state
      }]);
    })
  }

  private emptyFeatureCollection(): FeatureCollection<Geometry, any> {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }
}
