import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Survey } from './core/models/survey.model';
import { ProjectService } from './features/project/project.service';

export const surveyResolver: ResolveFn<Survey> = (route) => {
  console.log('Survey Resolver');

  const projectsService = inject(ProjectService);
  const router = inject(Router);

  const surveyId = parseInt(route.paramMap.get("surveyid") as string);
  const project = projectsService.project()!;
  const survey = project.surveys.find((a) => a.id === surveyId);

  projectsService.activeBaselineId.set(surveyId);

  if (typeof survey === 'undefined') {
    router.navigate(['/']);
    return EMPTY;
  }

  if (project.ukHabVersion === 'ukhabappv3') {
    return projectsService.loadBaseline([surveyId]).pipe(
      switchMap(() => {
        const baseline = projectsService.baseline();
        return of(baseline.find((a) => a.id === surveyId)!);
      })
    );
  }
  return of(survey);
}

