import { z } from 'zod';
import { daforScores, dominScores } from '../services/utils.service';

export const AttachmentSchema = z.object({
  id: z.number(),
  url: z.string(),
  size: z.number(),
  quadrat: z.boolean().optional()
});

const SpeciesSchema = z
  .object({
    id: z.number(),
    sampleId: z.number().optional(),
    spsName: z.string(),
    baselineId: z.number(),
    // qId: z.number().optional(),
    // qType: z.string().optional(),
    // qNum: z.number().optional(),
    qNumber: z.number().optional(),
    // qSize: z.number().optional(),
    // qLength: z.number().optional(),
    spsDomin: z.enum(dominScores).optional(),
    spsDafor: z.enum(daforScores).optional(),
    spsNote: z.string().optional(),
    spsOther: z.string().optional(),
    images: AttachmentSchema.array().optional(),
    geometry: z.any().optional()
  })
  .strict();

const SpeciesSamplesSchema = z.object({
  id: z.number(),
  qNum: z.number(),
  qType: z.enum(['quadrat0', 'linear1']),
  qLength: z.number().optional(),
  qSize: z.number().optional(),
  geometry: z.any().optional(),
  images: AttachmentSchema.array().optional(),
  baselineId: z.number()
}).strict();

const truncateNumber = (num: number, digits = 4): number => {
  const strNum = num?.toString();
  if (!strNum || isNaN(num)) {
    return num;
  }

  const decimalIndex = strNum.indexOf('.');
  if (decimalIndex === -1 || strNum.length - decimalIndex <= digits) {
    return num;
  }

  const truncated = strNum.slice(0, decimalIndex + digits + 1);
  return parseFloat(truncated);
};

const SurveyLogSchema = z
  .object({
    operation: z.string(),
    userId: z.number(),
    createdAt: z.string().datetime({ offset: true }),
    diff: z.any().optional(),
    recordId: z.number().optional()
  })
  .strict();

export const SurveySchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    featName: z.string().optional().nullable(),
    ukHabPName: z.string().optional(),
    ukHabPCode: z.string().optional(),
    ukHabCCode: z.string().optional(),
    ukHabS1Cod: z.string().optional(),
    ukHabS1Nam: z.string().optional(),
    ukHabS2Cod: z.string().optional(),
    ukHabS2Nam: z.string().optional(),
    ukHabS3Cod: z.string().optional(),
    ukHabS3Nam: z.string().optional(),
    ukHabS4Cod: z.string().optional(),
    ukHabS4Nam: z.string().optional(),
    ukHabS5Cod: z.string().optional(),
    ukHabS5Nam: z.string().optional(),
    ukHabS6Cod: z.string().optional(),
    ukHabS6Nam: z.string().optional(),
    type: z.string(),
    distinctiv: z.number().optional(),
    conScore: z.any(),
    conResult: z.string().optional(),
    conNotes: z.string().optional(),
    geometry: z.any(),
    area: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    length: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    latitude: z.number().optional().transform(t => t ? truncateNumber(t,6) : t),
    longitude: z.number().optional().transform(t => t ? truncateNumber(t,6) : t),
    habNotes: z.string().optional(),
    images: AttachmentSchema.array(),
    metHabType: z.string().optional(),
    metBrdHab: z.string().optional(),
    metHab: z.string().optional(),
    metHabIrr: z.boolean().nullable().optional(),
    metMsg: z.string().optional(),
    userId: z.number(),
    qRecorded: z.number(),
    spsTotal: z.number().optional(),
    spsPerQ: z.number().optional(),
    nativeWSps: z.number().optional(),
    conCrit: z.string().optional(),
    conCrit1: z.any().optional(),
    conCrit2: z.any().optional(),
    conCrit3: z.any().optional(),
    conCrit4: z.any().optional(),
    conCrit5: z.any().optional(),
    conCrit6: z.any().optional(),
    conCrit7: z.any().optional(),
    conCrit8: z.any().optional(),
    conCrit9: z.any().optional(),
    conCrit10: z.any().optional(),
    conCrit11: z.any().optional(),
    conCrit12: z.any().optional(),
    conCrit13: z.any().optional(),
    conCritX: z.any().optional(),
    noteCritA: z.string().optional(),
    noteCritB: z.string().optional(),
    noteCritC: z.string().optional(),
    noteCritD: z.string().optional(),
    noteCritE: z.string().optional(),
    noteCritF: z.string().optional(),
    noteCritG: z.string().optional(),
    noteCritH: z.string().optional(),
    noteCritI: z.string().optional(),
    noteCritJ: z.string().optional(),
    noteCritK: z.string().optional(),
    noteCritL: z.string().optional(),
    noteCritM: z.string().optional(),
    noteCritA1: z.string().optional(),
    noteCritA2: z.string().optional(),
    noteCritB1: z.string().optional(),
    noteCritB2: z.string().optional(),
    noteCritC1: z.string().optional(),
    noteCritC2: z.string().optional(),
    noteCritD1: z.string().optional(),
    noteCritD2: z.string().optional(),
    noteCritE1: z.string().optional(),
    noteCritE2: z.string().optional(),
    noteCritUE1: z.string().optional(),
    noteCritUE2: z.string().optional(),
    species: SpeciesSchema.array(),
    samples: SpeciesSamplesSchema.array(),
    metBUnits: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    metHUnits: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
    state: z.number(),
    stratSig: z.string().nullable().optional(),
    logs: SurveyLogSchema.array()
  })
  .strict();

export type Survey = z.infer<typeof SurveySchema>;
export type SurveyLog = z.infer<typeof SurveyLogSchema>;
export type Attachment = z.infer<typeof AttachmentSchema>;
export type Species = z.infer<typeof SpeciesSchema>;
export type SpeciesSample = z.infer<typeof SpeciesSamplesSchema>;
