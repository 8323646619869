import { z } from 'zod';
import { AttachmentSchema } from './survey.model';

export const SpeciesFeatureNoteSchema = z.object({
  id: z.number(),
  sfNum: z.number().optional(),
  sfCat: z.string(),
  sfOCat: z.string().optional(),
  sfNote: z.string().optional(),
  geometry: z.any(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
  images: AttachmentSchema.array().optional()

}).strict();

export type SpeciesFeatureNote = z.infer<typeof SpeciesFeatureNoteSchema>;
