import { HttpClient } from "@angular/common/http";
import { Injectable, inject, signal } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Project, ProjectMembership, ProjectSchema, ProjectSummary, ProjectSummarySchema } from "../models/project.model";
import { Survey } from "../models/survey.model";
import { ImportPreSurveyQuery, ImportPreSurveyQueryInput } from "../queries/pre-survey.queries";

import { ApiService } from "./api.service";
import { demoProject } from './demo.project';
import { MultiPolygon, Polygon } from "geojson";

const USE_DEMO_PROJECT = false;

@Injectable({ providedIn: 'root' })
export class ProjectsService {

  httpClient = inject(HttpClient);
  apiService = inject(ApiService);

  currentProject = signal<Project | null>(null);
  projects = signal<ProjectSummary[] | undefined>(undefined);

  query(id: number) {
    return this.httpClient.get<ProjectSummary[]>(`${environment.ukhabApiUrl}/organisations/${id}/projects`).pipe(
      map(response => {
        console.log("Response", response);
        return ProjectSummarySchema.array().parse(response);
        // return response;
      })
    );
  }

  clearCurrentProject() {
    this.currentProject.set(null);
  }

  get(id: number, isUpdate: boolean = false): Observable<Project> {
    const currentProject = this.currentProject();
    if (currentProject?.id === id && !isUpdate) {
      return of(currentProject);
    }

    if (USE_DEMO_PROJECT) {
      const project = ProjectSchema.parse(demoProject);
      this.currentProject.set(project);
      return of(project);
    }

    return this.httpClient.get<Project>(`${environment.apiUrl}/project?id=${id}`).pipe(
      map((result) => {
        const project = ProjectSchema.safeParse(result);
        if (project.success) {
          this.currentProject.set(project.data);
        } else {
          console.warn(project.error);
        }
        return (project as any).data as Project;
      })
    );
  }

  getSurvey(id: number, surveyId: number): Observable<Survey | undefined> {
    return this.get(id, true).pipe(
      map((project) => project.surveys.find((a) => a.id === surveyId))
    );
  }

  countRecords(projectId: number, surveyId: number): Observable<number> {
    const query = `query UKHabCountRecords {
      count: recordsAggregate(where: {projectId: ${projectId}, surveyId: ${surveyId}}, function: "COUNT")
    }`;

    return this.apiService.graphql(query, {
      surveyId
    }).pipe(
      map(r => r.count)
    );
  }

  importPreSurvey(projectId: number, surveyId: number, file: File, pathMap: { [key: string]: string }) {
    let form = new FormData();
    form.append('query', ImportPreSurveyQuery);
    form.append('file', file);
    // form.append('variables', JSON.stringify(options.variables));
    const input: ImportPreSurveyQueryInput = {
      map: pathMap,
      surveyId,
      projectId,
      format: 'Shapefile',
      epsgCode: null
    };
    form.append('variables', JSON.stringify({ input }));
    return this.httpClient.post<{ data: { result: any } }>(`${environment.coreoApiUrl}/graphql`, form).pipe(
      map(response => response.data.result)
    );
  }

  updateProject(id: number, data: Partial<Project>) {
    return this.httpClient.put(`${environment.apiUrl}/project?id=${id}`, data).pipe(
      tap(() => {
        const currentProject = this.currentProject();
        if (currentProject && currentProject.id === id) {
          this.currentProject.set({
            ...currentProject,
            ...data
          });
        }
        const projects = this.projects();
        if (projects) {
          const project = projects.find(p => p.id === id);
          if (project) {
            const updatedProject = {
              ...project,
              ...data
            };
            const index = projects.indexOf(project);
            projects[index] = updatedProject;
            this.projects.set([...projects]);
          }
        }
      })
    )
  }

  deleteProject(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/project?id=${id}`);
  }

  createProject(organisationId: number, name: string, description: string, memberships: ProjectMembership[], geometry?: Polygon | MultiPolygon) {
    return this.httpClient.post<{ id: number }>(`${environment.apiUrl}/project`, {
      name,
      description,
      organisationId,
      geometry,
      memberships
    }).pipe(
      tap(response => {
        const projects: ProjectSummary[] = [
          ...(this.projects() ?? []),
          {
            id: response.id,
            name,
            description,
            organisationId,
            geometry: geometry ?? null,
            ukHabVersion: 'ukhabappv3',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          }
        ];
        projects.sort((a, b) => a.name.localeCompare(b.name));
        this.projects.set(projects);
      }),
      map(response => response.id)
    );
  }
}
